import React from 'react';
import Layout from '../components/Layout';

const ThankYou = () => (
  <Layout>
    <section className="section section--gradient">
      <div className="container">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <div className="section">
              <h1 className="title is-size-2 has-text-weight-bold is-bold-light">
                Благодарности
              </h1>
              <p>Благодаря ви за проявеният интерес!</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
);

export default ThankYou;
